/*
    Icon Font orion-font generated from orioniconlibrary.com
  */

  @font-face {
  font-family: "orion-font";
  src: url("./orion-font.eot");
  src: url("./orion-font.eot?#iefix") format("embedded-opentype"),
       url("./orion-font.woff2") format("woff2"),
       url("./orion-font.woff") format("woff"),
       url("./orion-font.ttf") format("truetype"),
       url("./orion-font.svg#orion-font") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "orion-font";
    src: url("./orion-font.svg#orion-font") format("svg");
  }
}

  [data-icon]::before { content: attr(data-icon); }

  [data-icon]::before,
  .o-add-circle-1::before,
.o-add-document-1::before,
.o-angle-down-1::before,
.o-angle-down-circle-1::before,
.o-angle-down-circle-2::before,
.o-angle-left-1::before,
.o-angle-left-circle-1::before,
.o-angle-right-1::before,
.o-angle-right-circle-1::before,
.o-angle-up-1::before,
.o-angle-up-circle-1::before,
.o-angle-up-circle-2::before,
.o-arrow-down-1::before,
.o-arrow-left-1::before,
.o-arrow-right-1::before,
.o-arrow-up-1::before,
.o-arrow-up-circle-1::before,
.o-arrow_left01-1::before,
.o-arrow_right01-1::before,
.o-avatar-1::before,
.o-bin-1::before,
.o-box-1::before,
.o-calls-1::before,
.o-cash-register-1::before,
.o-cell-tower-1::before,
.o-checkbox-checked-square-1::before,
.o-checkbox-checked-square-2::before,
.o-checked-circle-1::before,
.o-checked-circle-2::before,
.o-checkmark-1::before,
.o-close-1::before,
.o-cogwheel-1::before,
.o-configuration-1::before,
.o-copied-to-clipboard-1::before,
.o-delete-circle-1::before,
.o-delete-circle-2::before,
.o-delete-file-1::before,
.o-document-saved-1::before,
.o-down-1::before,
.o-download-1::before,
.o-download-file-1::before,
.o-edit-1::before,
.o-edit-document-1::before,
.o-entrance-1::before,
.o-euro-sign-1::before,
.o-facebook-1::before,
.o-facebook-2::before,
.o-flame-1::before,
.o-hot-coffee-1::before,
.o-inbox-1::before,
.o-info-help-1::before,
.o-info-help-2::before,
.o-instagram-1::before,
.o-layout-1::before,
.o-left-key-1::before,
.o-lightning-bolt-1::before,
.o-like-hand-1::before,
.o-linkedin-1::before,
.o-lock-1::before,
.o-lock-opened-1::before,
.o-mail-1::before,
.o-minus-circle-1::before,
.o-money-1::before,
.o-money-bag-euro-1::before,
.o-money-box-1::before,
.o-open-mail-1::before,
.o-outbox-1::before,
.o-pay-1::before,
.o-pin-1::before,
.o-play-1::before,
.o-point-hand-1::before,
.o-print-1::before,
.o-priority-warning-1::before,
.o-priority-warning-2::before,
.o-protect-file-1::before,
.o-question-help-1::before,
.o-radar-signal-1::before,
.o-read-email-1::before,
.o-reception-1::before,
.o-remove-file-1::before,
.o-save-file-1::before,
.o-search-file-1::before,
.o-share-1::before,
.o-sliders-1::before,
.o-speed-1::before,
.o-stream-video-1::before,
.o-sun-1::before,
.o-twitter-1::before,
.o-up-1::before,
.o-upload-1::before,
.o-visible-1::before,
.o-wall-clock-1::before,
.o-wallet-1::before,
.o-wheat-1::before,
.o-wifi-signal-1::before,
.o-xing-1::before,
.o-zoom-1::before {
    display: inline-block;
  font-family: "orion-font";
  font-style: normal;
  font-weight: bold;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  }

  .o-add-circle-1::before { content: "\f720"; }
.o-add-document-1::before { content: "\f721"; }
.o-angle-down-1::before { content: "\f722"; }
.o-angle-down-circle-1::before { content: "\f71f"; }
.o-angle-down-circle-2::before { content: "\f723"; }
.o-angle-left-1::before { content: "\f724"; }
.o-angle-left-circle-1::before { content: "\f725"; }
.o-angle-right-1::before { content: "\f726"; }
.o-angle-right-circle-1::before { content: "\f727"; }
.o-angle-up-1::before { content: "\f728"; }
.o-angle-up-circle-1::before { content: "\f729"; }
.o-angle-up-circle-2::before { content: "\f72a"; }
.o-arrow-down-1::before { content: "\f72b"; }
.o-arrow-left-1::before { content: "\f72c"; }
.o-arrow-right-1::before { content: "\f72d"; }
.o-arrow-up-1::before { content: "\f72e"; }
.o-arrow-up-circle-1::before { content: "\f72f"; }
.o-arrow_left01-1::before { content: "\f777"; }
.o-arrow_right01-1::before { content: "\f778"; }
.o-avatar-1::before { content: "\f730"; }
.o-bin-1::before { content: "\f731"; }
.o-box-1::before { content: "\f732"; }
.o-calls-1::before { content: "\f733"; }
.o-cash-register-1::before { content: "\f734"; }
.o-cell-tower-1::before { content: "\f735"; }
.o-checkbox-checked-square-1::before { content: "\f782"; }
.o-checkbox-checked-square-2::before { content: "\f783"; }
.o-checked-circle-1::before { content: "\f736"; }
.o-checked-circle-2::before { content: "\f737"; }
.o-checkmark-1::before { content: "\f738"; }
.o-close-1::before { content: "\f739"; }
.o-cogwheel-1::before { content: "\f73a"; }
.o-configuration-1::before { content: "\f73b"; }
.o-copied-to-clipboard-1::before { content: "\f73c"; }
.o-delete-circle-1::before { content: "\f73d"; }
.o-delete-circle-2::before { content: "\f73e"; }
.o-delete-file-1::before { content: "\f73f"; }
.o-document-saved-1::before { content: "\f740"; }
.o-down-1::before { content: "\f741"; }
.o-download-1::before { content: "\f742"; }
.o-download-file-1::before { content: "\f743"; }
.o-edit-1::before { content: "\f744"; }
.o-edit-document-1::before { content: "\f745"; }
.o-entrance-1::before { content: "\f746"; }
.o-euro-sign-1::before { content: "\f747"; }
.o-facebook-1::before { content: "\f748"; }
.o-facebook-2::before { content: "\f779"; }
.o-flame-1::before { content: "\f749"; }
.o-hot-coffee-1::before { content: "\f74a"; }
.o-inbox-1::before { content: "\f74b"; }
.o-info-help-1::before { content: "\f74c"; }
.o-info-help-2::before { content: "\f74d"; }
.o-instagram-1::before { content: "\f77a"; }
.o-layout-1::before { content: "\f784"; }
.o-left-key-1::before { content: "\f74e"; }
.o-lightning-bolt-1::before { content: "\f74f"; }
.o-like-hand-1::before { content: "\f750"; }
.o-linkedin-1::before { content: "\f751"; }
.o-lock-1::before { content: "\f752"; }
.o-lock-opened-1::before { content: "\f753"; }
.o-mail-1::before { content: "\f754"; }
.o-minus-circle-1::before { content: "\f755"; }
.o-money-1::before { content: "\f756"; }
.o-money-bag-euro-1::before { content: "\f757"; }
.o-money-box-1::before { content: "\f758"; }
.o-open-mail-1::before { content: "\f759"; }
.o-outbox-1::before { content: "\f75a"; }
.o-pay-1::before { content: "\f75b"; }
.o-pin-1::before { content: "\f75c"; }
.o-play-1::before { content: "\f75d"; }
.o-point-hand-1::before { content: "\f75e"; }
.o-print-1::before { content: "\f75f"; }
.o-priority-warning-1::before { content: "\f760"; }
.o-priority-warning-2::before { content: "\f761"; }
.o-protect-file-1::before { content: "\f762"; }
.o-question-help-1::before { content: "\f763"; }
.o-radar-signal-1::before { content: "\f764"; }
.o-read-email-1::before { content: "\f765"; }
.o-reception-1::before { content: "\f766"; }
.o-remove-file-1::before { content: "\f767"; }
.o-save-file-1::before { content: "\f768"; }
.o-search-file-1::before { content: "\f769"; }
.o-share-1::before { content: "\f76a"; }
.o-sliders-1::before { content: "\f76b"; }
.o-speed-1::before { content: "\f76c"; }
.o-stream-video-1::before { content: "\f77b"; }
.o-sun-1::before { content: "\f76d"; }
.o-twitter-1::before { content: "\f76e"; }
.o-up-1::before { content: "\f76f"; }
.o-upload-1::before { content: "\f770"; }
.o-visible-1::before { content: "\f785"; }
.o-wall-clock-1::before { content: "\f771"; }
.o-wallet-1::before { content: "\f772"; }
.o-wheat-1::before { content: "\f773"; }
.o-wifi-signal-1::before { content: "\f774"; }
.o-xing-1::before { content: "\f775"; }
.o-zoom-1::before { content: "\f776"; }

  