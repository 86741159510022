body {
  font-family: NunitoRegular, serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* loading loadProfile upload CSS */
#myProgress {
  background-color: #F6F6F6;
  width: 100%;
  border-radius: 10px!important;
}

#myBar {
  text-align: center;
  width: 1%;
  height: 30px;
  background-color: #F6F6F6;
}

#progressValue {
  color: white;
}

.add-white::-webkit-input-placeholder{
  color: #ffffff !important;
  transition-duration: 1s;
}

.add-white{
  color: #ffffff !important;
  transition-duration: 1s;
}