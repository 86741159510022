:root {
    --customgreen: #fe821e;
    --black: #041133;
}
/* Font Family */
@font-face {
    font-family: NunitoRegular;
    src: url("./fonts/Nunito-Regular.ttf");
}
@font-face {
    font-family: NunitoSemiBold;
    src: url("./fonts/Nunito-SemiBold.ttf");
}
/* End */
/* GLOBAL STYLE */
.pb-8 {
    padding-bottom: 8rem !important;
}
body {
    margin: 0;
    font-family: NunitoRegular, serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
#root {
    font-size: 18px;
    position: relative;
    min-height: 100%;
}
html, body, #page, #root .login-page {
    height: 100%
}
h1, h2, h3, h4, h5, h6 {
    font-family: NunitoSemiBold, serif;
}
h1 {
    font-size: 48px;
}
h2 {
    font-size: 32px!important;
    margin-bottom: 33px!important;
}
.mb-25 {
    margin-bottom: 25px;
}
.eless-icon:hover {
    color: #FE821E;
    transition-duration: 0.2s;
    cursor: pointer;
}
.eless-icon {

    font-size: 24px;
    transition-duration: 0.2s;
}
#personal-data-icon {
    position: absolute;
    right: 20px;
}
.personal-data-wrapper {
    border-right: 1px solid black;
}
.small-icon {
    font-size: 24px;
}
.medium-icon {
    font-size: 32px;
}
/* END */
/* Header & Footer */
header, footer {
    background-color: #041133;
    overflow: hidden;
    padding: 1rem 0;
    height: 85px;
}
footer {
    position: absolute;
    width: 100%;
    bottom: -100px;
}
.bottom-inherit {
    bottom: inherit;
}
header {
    margin-bottom: 85px!important;
}
.section-content {
    margin: 0 auto;
    color: #fff;
    max-width: 1140px;
}
.site-branding-container {
    display: inline-block;
}
.menu-link-wrapper {
    float: right;
    position: relative;
    width: 140px;
    margin-top: 10px;
    cursor: pointer;
    height: 45px;
}
.menu-link-text {
    top: 0;
    left: 0;
    position: absolute;
    color: #fff;
    font-size: 22px;
    font-family: NunitoSemiBold, serif;
}
.menu-link-icon{
    margin-left: 20px;
    position: absolute;
    right: 0;
    width: 58px;
    height: 34px;
    z-index: 20;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}
.menu-link-icon div {
    height: 4px;
    border-radius: 5px;
    display: block;
    position: absolute;
    width: 100%;
    background: #d3531a;
    opacity: 1;
    right: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}
/* Menu Icon Animation */
.menu-link-icon.cross .top-bar {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 10px;
    right: 5px;
}
.menu-link-icon.cross .bot-bar {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 100%;
    bottom: 20px;
    right: 5px;
}
.menu-link-icon .top-bar{
    width: 100%;
    background-color: #fff;
    position: absolute;
    right: 0;
    top: 0;
}
.menu-link-icon .mid-bar{
    width: 35px;
    background-color: #fe821e;
    position: absolute;
    top: 15px;
    right: 0;
}
.menu-link-icon .bot-bar{
    width: 45px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
}
.menu-link-icon.cross .mid-bar {
    width: 0;
    right: 40px;
}
.full-page-menu {
    width: 100%;
    height: 100%;
    background-color: #041133;
    z-index: 10;
    position: absolute;
    margin-right: 10px;
    top: 0;
    color: #fff;
    font-size: 48px;
    font-family: NunitoSemiBold, serif;
}
.disable-scrolling {
    height: 100%;
    overflow: hidden
}
.fade-enter {
    opacity: 0.01;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
}

.fade-leave {
    opacity: 1;
}

.fade-leave.fade-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}
.eless-menu-links {
    margin-top: 15%;
}
@media screen and (max-width: 300px) {
    .eless-menu-links a {
        padding-left: 4% !important;
    }
}
@media screen and (max-width: 320px) and (min-width: 301px){
    .eless-menu-links a {
        padding-left: 10%!important;
    }
}
@media screen and (max-width: 390px) and (min-width: 321px){
    .eless-menu-links a {
        padding-left: 18%!important;
    }
}
@media screen and (max-width: 420px) and (min-width: 391px){
    .eless-menu-links a {
        padding-left: 22%!important;
    }
}
@media screen and (max-width: 500px) and (min-width: 421px){
    .eless-menu-links a {
        padding-left: 26%!important;
    }
}
@media screen and (max-width: 700px) and (min-width: 500px){
    .eless-menu-links a {
        padding-left: 31%!important;
    }
}
@media screen and (max-width: 830px){
    .eless-menu-links {
        margin-top: 150px!important;
    }
}
@media screen and (max-width: 830px) and (min-width: 701px){
    .eless-menu-links a {
        padding-left: 34%!important;
    }
}
@media screen and (max-width: 991px) and (min-width: 831px){
    .eless-menu-links a {
        padding-left: 37%!important;
    }
}
.eless-menu-links a {
    padding-left: 45%;
    display: block;
}
.eless-menu-link {
    text-decoration: none;
    color: #fff;
    display: block;
    cursor: pointer;
}
.eless-menu-link:hover {
    color: #0b071f!important;
    background-color: #f3f3f3;
    text-decoration: none;
}
/* End Header & Footer */
/* Orion Icons */
.o-arrow_left01-1 {
    position: absolute;
    left: 70px;
    bottom: 12px;
}
.o-arrow_right01-1 {
    position: absolute;
    right: 70px;
    bottom: 12px;
}
.o-edit-1, .o-bin-1 {
    font-weight: normal!important;
}
.o-arrow_left01-1:before {
    font-weight: normal!important;
    font-size: 16px;
}
.o-arrow_right01-1:before {
    font-weight: normal!important;
    font-size: 16px;
}
.active-icon {
    color: #fe821e;
}
/* End */
/* Breadcrumb */
@media screen and (min-width: 1006px) {
    #thirdBreadcrumb {
        padding: 0!important;
    }
}
.eless-breadcrumb .circle {
    width: 31px;
    height: 31px;
    border: 1px solid;
    border-radius: 15px;
    padding-left: 10px;
    margin-right: 10px;
    line-height: 1.6;
}
.eless-breadcrumb .first.circle {
    padding-left: 9px!important;
}
.eless-breadcrumb .active {
    color: #fe821e!important;
    font-weight: bold;
}
.eless-breadcrumb .active .circle {
    border-width: 2px;
}
.eless-breadcrumb a {
    display: inline-block;
}
.eless-breadcrumb {
    color: #9499A6;
    background-color: #f3f3f3;
    min-height: 66px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.2);
    border-radius: 10px;
    line-height: 1.5;
    margin-bottom: 25px;
    text-align: left;
    padding-top: 18px;
    padding-bottom: 15px;
}
.eless-breadcrumb .bc-item {
    text-align: left;
}
.eless-breadcrumb .md-text-center {
    margin: 0 auto;
    display: inline-block;
}
.eless-breadcrumb div {
    display: inline-block;
}
.eless-breadcrumb a{
    color: #041133;
}
.eless-breadcrumb a:hover{
    color: #041133;
    text-decoration: none;
}
/* End */
/* Push Messages */
/* success */
.eless-success {
    position: relative;
    background-color: rgb(213, 235, 229);
    color: #0b071f;
    padding: 19px 75px 8px 75px!important;
    min-height: 60px;
    border-radius: 5px;
    -webkit-transition: opacity 1s ease-in!important;
    -moz-transition: opacity 1s ease-in!important;
    -ms-transition: opacity 1s ease-in!important;
    -o-transition: opacity 1s ease-in!important;
    transition: opacity 1s ease-in!important;
    opacity: 1;
}
.eless-success span.o-checked-circle-1 {
    color: rgb(21, 130, 95) !important;
    position: absolute;
    left: 25px;
    top: 8px;
}
.eless-sucess.alert-dismissible .close span {
    font-size: 38px!important;
    font-weight: 200!important;
}
.eless-sucess .alert-dismissible .close {
    top: 6px;
    color: #041133!important;
}

/* error */
.eless-error {
    position: relative;
    background-color: #ffdfdb;
    color: #0b071f;
    padding: 19px 30px 8px 75px!important;
    min-height: 60px;
    border-radius: 5px;
    -webkit-transition: opacity 1s ease-in!important;
    -moz-transition: opacity 1s ease-in!important;
    -ms-transition: opacity 1s ease-in!important;
    -o-transition: opacity 1s ease-in!important;
    transition: opacity 1s ease-in!important;
    opacity: 1;
}


/*info */
.eless-info {
    position: relative;
    background-color: #E9EAF5;
    color: #0b071f;
    padding: 19px 30px 16px 75px!important;
    min-height: 60px;
    border-radius: 5px;
    -webkit-transition: opacity 1s ease-in!important;
    -moz-transition: opacity 1s ease-in!important;
    -ms-transition: opacity 1s ease-in!important;
    -o-transition: opacity 1s ease-in!important;
    transition: opacity 1s ease-in!important;
    opacity: 1;
}

.eless-info .o-info-help-1 {
    color: #383842 !important;
    position: absolute;
    left: 25px;
    top: 8px;
}





.jquery-error{
    display: none;
}
#resetPasswordPopup.modal-dialog {
    max-width: 700px!important;
}
#resetPasswordPopup .modal-header {
    border-bottom: none;
    border-top: 3px solid #FE821E;
}
#resetPasswordPopup .password-wrapper .o-visible-1 {
    position: absolute;
    right: 24px;
    top: 8px;
    font-size: 30px;
}
#resetPasswordPopup .password-wrapper {
    position: relative;
}
.eless-error.alert-dismissible .close span {
    font-size: 38px!important;
    font-weight: 200!important;
}
.eless-error .alert-dismissible .close {
    top: 6px;
    color: #041133!important;
}
.eless-error span.o-delete-circle-1 {
    color: #c71006!important;
    position: absolute;
    left: 25px;
    top: 8px;
}
.contract-row {
    border-bottom: 1px solid #041133;
    border-top: 1px solid #041133;
    padding: 15px 0;
    margin-top: -1px;
}
.accountsetting-row {
    border-bottom: 1.5px solid #041133;
    padding-bottom: 10px;
}
.link {
    color: #041133 !important;
    transition-duration: 0.2s;
}

.link:hover {
    color: #FE821E !important;
    transition-duration: 0.2s;
}

.hide-error {
    -webkit-transition: opacity 0s ease-in!important;
    -moz-transition: opacity 0s ease-in!important;
    -ms-transition: opacity 0s ease-in!important;
    -o-transition: opacity 0s ease-in!important;
    transition: opacity 0s ease-in!important;
    position: absolute!important;
    opacity: 0;
}
.eless-dynamic-error.alert-dismissible .close span {
    font-size: 38px!important;
    font-weight: 200!important;
}
.eless-dynamic-error .alert-dismissible .close {
    top: 6px;
    color: #041133!important;
}
.eless-dynamic-error span.o-delete-circle-1 {
    color: #c71006!important;
    position: absolute;
    left: 25px;
    top: 8px;
}
.eless-dynamic-error {
    position: relative;
    background-color: #ffdfdb;
    color: #0b071f;
    padding: 19px 75px 8px 75px!important;
    min-height: 60px;
    border-radius: 5px;
    display: none;
}
.eless-update {
    border-top: 4px solid #FE821E !important;
    border-bottom: 1px solid #0b071f!important;
    border-left: 1px solid #0b071f !important;
    border-right: 1px solid #0b071f!important;
    border-radius: 5px;
    background-color: #ffffff;
    min-height: 65px;
}
/* End */
/* Baustellen Seite */
#constructionSiteWrapper .o-configuration-1 {
    font-size: 68px;
}
#constructionSiteWrapper {
    text-align: center;
}

/* LOGIN */
.background-image {
    background: url("./media/lager-bauarbeiter.jpeg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    overflow: auto;
}
#login-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 540px;
    border-radius: 5px;
    margin-top: 20%;
    transform: translateY(-20%);
    background-color: #ffffff;
    border-top: 3px solid #FE821E;
    padding-top: 25px;
    opacity: 0;
    transition-duration: 0.8s;
}
.show-login-wrapper {
    transition-duration: 0.8s;
    opacity: 1!important;
    margin-top: 18%!important;
}
#login-body.eless-form input {
    margin-top: 0!important;
}
#login-body {
    padding: 20px 15%;
}
.captcha-wrapper{
    margin-bottom: 20px;
}
.captcha-wrapper div div div {
    margin: 0 auto;
}
#login-header {
    text-align: center;
}
#login-header img {
    margin: 15px 0 10px 0;
}
.login-cta {
    min-width: inherit!important;
    float: inherit!important;
}
.login-title {
    text-align: center;
    margin-bottom: 35px!important;
    font-family: NunitoRegular, serif;
}
#login-footer {
    padding-bottom: 20px;
}
/* LOGIN END */

/* Eless Buttons */
.tarif-btn {
    min-width: 250px!important;
    min-height: initial!important;
    float: initial!important;
}
.eless-btn {
    display: inline-block;
    border-radius: 5px;
    line-height: 40px;
    min-width: 211px;
    font-size: 22px;
    background-size: cover;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
    padding: 2px 15px 5px 15px;
    font-family: NunitoRegular, serif;
    cursor: pointer;
}
.eless-cta-btn {
    color: #fff;
    background-color: #FE821E;
    border: 1px solid #FE821E;
    transition-duration: 0.4s;
    float: right;
    min-width: 320px;
    min-height: 65px;
    font-size: 22px;
}
.eless-cta-btn:hover {
    background-color: #CC6918;
    border: 1px solid #CC6918;
    transition-duration: 0.4s;
}
.vertical-center:hover {
    color: #FE821E;
}
/* End */
/* Consumption Point */
.eless-checkbox:hover {
    color: #FE821E;
    transition-duration: 0.2s;
}
.eless-checkbox .checkbox-text {
    font-size: 18px;
    padding-left: 10px;
}
.eless-checkbox {
    min-width: 112px;
    transition-duration: 0.2s;
    cursor: pointer;
    font-size: 22px;
}
.eless-checkbox.consumption-point-checkbox .o-checkbox-checked-square-1,
.eless-checkbox.consumption-point-checkbox .o-layout-1{
    float: left;
}
.eless-checkbox.consumption-point-checkbox {
    bottom: 5px;
    min-width: 112px;
    left: 8px;
    transition-duration: 0.2s;
    cursor: pointer;
    font-size: 18px !important;
}
.cspoint-action  {
    bottom: -3px\0!important;
}
.cspoint-action {
    position: absolute;
    bottom: 2px;
    left: 20px;
}
.cp-modal-trigger{
    min-height: 217px;
}
.csPointBoxWrapper {
    margin-bottom: 20px;
}
.consumptionPointBox.checked {
    border: 1px solid #FE821E;
}
.consumptionPointBox {
    background-color: #f3f3f3;
    padding: 0 0 14px 0;
    border-radius: 10px;
    border: 1px solid transparent;
    height: auto;
    min-height: 217px;
}
.csPointBoxWrapper {
    padding-left: 0!important;
    padding-right: 20px!important;
}
.pencil-icon {
    margin-left: -25px;
    margin-right: 15px;
}
.street-title {
    margin-bottom: 13px;
}
.add-consumptionpoint {
    font-family: NunitoSemiBold, serif;
    position: absolute;
    top: 8px;
    left: 40px;
}
.cspoint-address, .zaehlertyp-box {
    margin-bottom: 30px;
}
.eless-ul div span{
    position: absolute;
    left: 15px;
}
.eless-ul div{
    margin-bottom: 14px;
    padding-left: 30px
}

.hasContractHeadline {
    text-align: left !important;
    margin-top: -3px;
}
#cspoint-filters button.active{
    background-color: #041133;
    border-radius: 5px;
    color:  #fff;
    transition-duration: 0.2s;
}
#cspoint-filters button.inactive:hover{
    border: 2px solid #041133;
    border-radius: 5px;
    color: #041133;
    transition-duration: 0.2s;
}
#cspoint-filters button{
    border: 2px solid transparent;
    padding: 2px 10px;
    cursor: pointer;
    transition-duration: 0.2s;
    margin-right: 15px;
    display:inline-block;
    background-color: #ffffff;
}
#cspoint-filters{
    transition-duration: 0.5s;
    position: absolute;
    display:inline-block;
    top: -3px;
    width: 50%;
    right: 300px;
    z-index: 0;
    opacity: 0;
    pointer-events: none;
}
#cspoint-filters.active{
    width: 100%;
    right: 30px;
    opacity: 1;
    pointer-events: auto;
}
.lds-ring.filter-lds.inactive {
    opacity: 0;
    transition-duration: 0.2s;
}
.lds-ring.filter-lds {
    position: absolute;
    left: inherit;
    top: -3px;
    right: 35px;
    opacity: 1;
    transition-duration: 0.2s;
    pointer-events: none;
    color: #fff;
}
.filter-spinner-wrapper {
    background-color: #041133;
    border-radius: 5px;
}
.filter-icon .o-sliders-1.inactive {
    opacity: 0;
    transition-duration: 0.2s;
}
.filter-icon .o-sliders-1.active {
    background-color: #041133;
    color:  #fff;
}
.filter-icon .o-sliders-1 {
    border: 2px solid transparent;
    padding: 5px 7px 8px 7px;
    transition-duration: 0.2s;
    cursor: pointer;
    border-radius: 5px;
    opacity: 1;
}
.filter-icon .o-sliders-1 {
    z-index: 999;
}
.filter-icon .o-sliders-1:hover {
    border: 2px solid #041133;
    transition-duration: 0.2s;
}
.filter-icon {
    text-align: right;
    position: relative;
}
.mb-100 {
    margin-bottom: 100px;
}
.bottom-row div{
    margin-bottom: 100px ;
}
/* Energytype */
#energytype {
    margin-bottom: 70px;
    position: relative;
}
/* End */
/*
Lieferstellen CSS
 */
.testHide {
    display: none!important;
    transition-duration: 0.2s;
}
#consumption-points-info-wrapper {
    display: none!important;
}
.waiting-animation{
    left: inherit!important;
    position: relative!important;
    top: inherit!important;
}
#paymentMethodForm {
    display: none;
}
.hide-form {
    display: none;
}
/*
LIEFERSTELLEN END
 */
/* Enplify CSS */
.fa-times:hover, .fa-pencil-alt:hover{
    transition-duration: 0.2s;
    color: #fe821e;
}
.fa-times, .fa-pencil-alt{
    transition-duration: 0.2s;
}
.pl-20p {
    padding-left: 20px!important;
}
/* button */
 .refreshBtn {
     background-color: white;
     border: 3px solid #041133;
     border-radius: 30px;
     min-width: 200px;
     color: #041133;
     font-weight: bold;
     font-size: 22px;
     transition-duration: 0.2s;
     height: 60px;
 }
.refreshIcon {
    cursor: pointer;
    transition-duration: 0.2s;
    width: 2em!important;
    height: 1.3em!important;
}
.spin {
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
.refreshIcon:hover {
    transition-duration: 0.2s;
    color: var(--customgreen);
}
/*
icons in inputfield
*/
.slpProfileSelect {
    position: absolute;
    top: 32px;
    background-color: transparent !important;
}
.input-spinner {
    position: relative;
}
.input-spinner svg {
    position: absolute;
    right: 9px;
    top: 18px;
    color: #041133;
}
.lds-ring.full-page-spinner {
    margin: 30px auto;
    width: 100%;
    position: relative!important;
    left: inherit;
    top: inherit;
    text-align: center;
}
.max-width-content {
    max-width: 1140px; margin: 0 auto 0 auto;
}
@media screen and (max-width: 1160px){
    .max-width-content {
        padding: 15px;
    }
}
.full-page-spinner-wrapper {
    width: 1140px;
    height: 100px;
    position: relative;
}
/* TariffsPage */
input, #consumptionPoints, #angebote, #duration, #tarif-wrapper {
    font-family: NunitoRegular, Roboto, Arial, sans-serif!important;
    color: #041133;
}
.filter-dropwdown {
    cursor: pointer;
    position: relative;
    letter-spacing: 0.18px;
    line-height: 1;
    margin-bottom: 15px;
}
.filter-dropwdown label {
    display: block;
}
.filter-dropwdown:hover {
    color: #fe821e;
}
.iconEnergySum {
    color: var(--black);
}
.iconNetFee {
    color: #041133;
}
.elessLogo {
    width: 112px !important;
    height: 112px !important;
    object-fit: contain;
}
.filter-box {
    margin-bottom: 30px;
}
.filter-box label {
    display: block;
}
.filter-options {
    display: none;
    font-size: 18px;
    line-height: 1;
}
.downloadLinkAGB {
    color: var(--black) !important;
}
.downloadLinkAGB:hover {
    color: var(--customgreen) !important;
    text-decoration: none;
}

.downloadLink {
    color: var(--black) !important;
    text-decoration: underline var(--black) !important;
}
.downloadLink:hover {
    color: var(--customgreen) !important;
    text-decoration: underline var(--customgreen) !important;
}
.energiepreis-block{
    vertical-align: top;
}
/* zzg /zuzüglich) tag */
.zzg {
    font-size: 14px;
}
#tariffs-page .control_indicator {
    top: 9px!important;
    left: 10px!important;
    height: 16px!important;
    width: 16px!important;
}
.tarifblock {
    border-right: 3px solid #fff;
}
/* End TariffsPage */
/* auto- complete list design (GETAG) */
.autocomplete-items {
    position: absolute;
    border: 1px solid #f3f3f3;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
    border-radius: 20px!important;
    background-color: #fff;
}
.font-inherit {
    font: inherit!important;
}
.autocomplete-items div:last-child {
    border-bottom: none!important;
}
.autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    border-bottom: 2px solid #f3f3f3;
}
.popup #street:disabled, .popup#houseNumber:disabled {
    opacity: 0.6;
}
/*when hovering an item:*/
.autocomplete-items div:first-child:hover {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.autocomplete-items div:last-child:hover {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.autocomplete-items div:hover {
    background-color: #041133;
    color: white;
}
/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
    background-color: #041133;
    color: white;
}
/* end */
.price-name {
    margin-bottom: 10px;
}
.round-tarif {
    border-radius: 20px!important;
}
.tarifblock, .offer-refresh-block {
  padding: 38px 50px 15px 24px;
  position: relative;
  background-color: #f3f3f3;
  color: #041133;
  line-height: 26px;
  text-align: left;
  transition-duration: 0.4s;
  border-radius: 5px;
}
.tariff-img-wrapper {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    width: 150px;
    height: 150px;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #0b071f;
}
.tariff-information-table tr th {
    width: 230px;
}
.details-icon {
    margin-left: 5px;
}
.tarifblock .show-details {
    text-align: center;
    padding-top: 14px;
    cursor: pointer;
}
.offer-refresh-block{
    font-size: 22px;
    border-radius: 10px;
    margin-bottom: 10px;
}
.offer-refresh-block span{
    line-height: 2.9;
}
#time-span {
    min-width: 285px;
    display: inline-block;
    line-height: inherit;
}
.tarifpreis {
  color: #041133;
  font-size: 22px;
  font-family: NunitoSemiBold;
  margin-bottom: 30px;
}
.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 50px;
    transition-duration: 0.2s;
}
.password-modal .modal-title {
    color: #041133;
    font-weight: 600!important;
}
.ReactModal__Body--open {
    overflow-y: hidden;
}
.modal-dialog {
    margin: 0!important;
}
.fade.modal-backdrop.show {
    height: 100%;
    width: 100%;
}
.fade.modal.show {
    padding-left: 0!important;
}
.modal-content {
    border: none!important;
}
.font-s-18 {
    font-size: 18px;
}
.tarif-description {
  font-size: 18px;
  margin-bottom: 10px;
  font-style: italic;
}
.tarifcol {
  padding: 15px 0;
}
#consumptionPointAdd {
  cursor: pointer;
  font-size: 22px;
  position: relative;
}
/* FONT AWSEOME STYLING BEGIN */
.alert-border {
    border: 2px #FD7A64 solid !important;
}
.alert-text {
    color: #FD7A64 !important;
}
#apiErrorPopup .modal-content {
    border: 2px #ffdfdb solid!important;
    background-color: #ffdfdb;
}
#apiErrorPopup .modal-body {
    background-color: #ffffff;
}
#apiErrorPopup .modal-header {
    padding: 10px 10px;
}
#apiErrorPopup .modal-title {
    padding-top: 6px;
    padding-left: 10px;
}
.error-page-container {
    background-color: #f3f3f3;
    padding: 40px;
    margin: 0 auto;
    text-align: center;
}
.error-page-content a:hover {
    text-decoration: none;
    color: inherit;
    border-bottom: none;
}
.error-page-content .button-link {
    border: none;
}
.error-page-content a {
    border-bottom-style: solid;
    border-bottom-width: .09091em;
    color: inherit;
    text-decoration: none;
}
.error-page-content .eless-weiter-btn {
    float: inherit;
}
.error-page-content {
    max-width: 700px;
    margin: 0 auto;
    position: relative;
    z-index: 50;
}
.dizzyIcon{
    opacity: 0.1;
    color: #9a9a9a;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
}
.fa-disabled {
    opacity: 0.6;
    cursor: not-allowed;
}
.refreshIconWrapper {
    float: right;
}
/* FONT AWESOME END*/
.borderBox {
    border-radius: 10px;
}
.marginLeftZehn {
    margin-left: 0 !important;
}
#consumptionPoints {
    width: 100%;
    vertical-align: middle;
    min-height: 300px;
}
#consumptionPoints.addBackground #consumptionPointWrapper {
    background-color: #f3f3f3;
    margin: 0 0 25px 0!important;
}
#consumptionPointWrapper {
    margin: 0 0 70px 0!important;
}
.warning-popup-footer {
    padding: 1rem;
}
.consumptionPointDelete {
    text-align: right;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #041133;
    color: #ffffff;
    padding: 7px 8px 5px 8px;
    min-height: 45px;
}
#consumptionPointEdit {
    margin-top: 5px;
    cursor: pointer;
    text-align: right;
    font-weight: bold;
    font-size: 14px;
    position: absolute;
    bottom: 10px;
    right: 20px;
    transition-duration: 0.2s;
}
#consumptionPointEdit:hover {
    color: #fe821e;
    transition-duration: 0.2s;
}
.warning-popup .modal-header{
    color: #fff;
    background-color:#dc3545;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}
input[type="text"]:active {
    border: none!important;
}
button[type="submit"]:disabled {
    opacity: 0.6;
}
.mobile-price {
    background-color: #f3f3f3;
    padding: 10px 20px;
}
#submitAnimation {
    position: absolute;
    width: 30px;
    right: 25px;
    bottom: -3px;
}
.login-spinner #loadingLoadProfileSpinnerIcon {
    bottom: 5px!important;
}
#loadingLoadProfileSpinnerIcon.special {
    bottom: 5px!important;
}
#loadingLoadProfileSpinnerIcon {
    position: absolute;
    width: 30px;
    right: 25px;
    bottom: 9px;
}
.ReactModal_ _Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}
.ReactModal__Overlay--before-close{
    opacity: 0;
}
.modal-form .ReactModal__Content::-webkit-scrollbar {
    width: 0 !important;
}
.section_heading{
    position: relative;
    display: inline-block;
}
.marker_line {
    border-radius: 10px;
    height: 20px;
    position: absolute;
    top: 33%;
    z-index: -1;
    width: 100%;
    transition-duration: 0.5s;
}
.yellow_marker {
    background-color: #FFD25A;
}
.green_marker {
    background-color: #fe821e;
}
.red_marker {
    background-color: #FD7A64;
}
.link {
    color: #75D4FF;
}
.eless-form input, .eless-form .custom-select{
    background-color: #f3f3f3;
}
#city0.city-select, #city.city-select {
    padding-bottom: 14px\0!important;
    padding-top: 12px\0!important;
}
.price-information-row td {
    text-align: right;
}
/* Select Arrow InternetE */
select::-ms-expand {
    border: none;
}
select.city-select {
    background-color: #f3f3f3;
}
.eless-form input::-webkit-input-placeholder {
    line-height: 60px;
}
.eless-form input {
    line-height: 60px;
}
.eless-form input::-webkit-input-placeholder {
    line-height: inherit\0;
}
.eless-form input {
    line-height: inherit\0;
}
.eless-form input, .eless-form select, #myProgress .myBar, #myProgress2, .myBar{
    height: 60px;
    font-size: 18px;
    border-radius: 5px!important;
    border: none;
    margin: 5px 0;
    padding: 5px 30px!important;
    color: #041133;
    width: 100%;
    font-family: NunitoRegular, serif;
}
.eless-form select.city-select-popupbox {
    align-items: stretch;
    padding-top: 20px!important;
}
#myProgress2 {
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100%;
    padding: 0!important;
}
.eless-form button:focus{
    outline: none;
}
#duration-fieldset.eless-form input{
    width: auto;
    margin-right: 20px;
}
#duration-fieldset{
    margin-bottom: 10px;
}
#duration-react {
    margin-bottom: 70px;
}
#duration-react input{
    min-width: 350px;
}
.eless-form select:focus, .eless-form input:focus {
    outline: none!important;
    border: none;
    box-shadow: none;
}
.eless-form input::placeholder {
    color: #041133;
}
.eless-form .fileupload-field {
    width: 96%;
}
.eless-form select * {
     border-radius: 40px;
     background-color: #ffffff;
}
.eless-form select *:hover {
    background-color: #f3f3f3!important;
}
.zipcity-separator{
    display: inline-block;
}
/* Radio Buttons LastType */
.filter-options .control {
    padding-top: 8px;
}
.control {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-right: 100px;
    cursor: pointer;
    padding-top: 6px;
    margin-top: -6px;
}
.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.control_indicator {
    position: absolute;
    top: 7px;
    left: 0;
    height: 24px;
    width: 24px;
    background: #fff;
    border: 1px solid #041133;
}
.control-radio .control_indicator {
    border-radius: 50%;
}
.control-checkbox .control_indicator {
    border-radius: 25%;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
    background: #ffffff;
}
.control input:checked ~ .control_indicator {
    background: #041133;
}
/* if not disabled and checked */
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
    background: #041133;
}
/* if disabled and checked */
.control input:disabled:checked ~ .control_indicator{
    background: #041133;
}
.control input:disabled:checked ~ .control_indicator:after{
    background: #041133;
}
.control input#lastgangfile:checked ~ .control_indicator {
    background: url('./media/Checkbox_ckecked.png');
    background-position: 0 -1px;
}
.control:hover input#lastgangfile:not([disabled]):checked ~ .control_indicator,
.control input#lastgangfile:checked:focus ~ .control_indicator {
    background: url('./media/Checkbox_ckecked.png');
    background-position: 0 -1px;
}
.control input:disabled ~ .control_indicator {
    background: #f3f3f3;
    opacity: 0.6;
    pointer-events: none;
}
.control_indicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
}
.control input:checked ~ .control_indicator:after {
    display: block;
}
.control-radio .control_indicator:after {
    left: 7px;
    top: 7px;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: #041133;
}
.control-radio input:disabled ~ .control_indicator:after {
    background: #f3f3f3;
}
.voucher-text-container {
    text-align: right;
    margin-top: 10px;
    cursor: pointer;
}
.voucher-text-full {
    font-weight: bold;
    font-style: italic;
}
.text-decoration-underline {
    text-decoration: underline;
    cursor: pointer;
}
.voucher-text {
    color: #75D4FF;
    text-decoration: underline;
}
/* End Radio */
input[type=file] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    z-index: 2;
}
.fileupload-field:hover ~ .fake-upload {
    border: 2px solid #041133;
}
.fake-upload {
    position: absolute;
    background-color: transparent !important;
    display: none;
}
.modal-body {
    font-size: 18px;
}
.modal-body input, .modal-body select{
    font-size: 18px;
}
.eless-zurueck-btn {
    height: 60px;
    background-color: white;
    border: 2px solid #041133;
    border-radius: 5px;
    min-width: 320px;
    color: #041133;
    font-weight: 500;
    font-size: 22px;
    transition-duration: 0.2s;
    position: relative;
}
.eless-zurueck-btn:hover {
    background-color: #e9eaf5;
    border: 2px solid #041133;
}
.thin-btn {
    height: 50px!important;
    min-width: 200px!important;
}
button:focus {
    outline: none!important;
}
.eless-weiter-btn {
    height: 60px;
    background-color: #041133;
    border: 2px solid #041133;
    border-radius: 5px;
    min-width: 325px;
    color: white;
    font-size: 22px;
    float: right;
    transition-duration: 0.2s;
    position: relative;
}
.eless-weiter-btn:hover, .refreshBtn:hover {
    background-color: rgba(4, 17, 51, 0.8);
    border: 2px solid  rgba(4, 17, 51, 0.8);
    color: white;
}
.eless-zurueck-btn img {
    margin-right: 15px;
}
.eless-weiter-btn img.benefit {
    margin-left: 50px;
}
.eless-weiter-btn.benefits {
    margin-top: 40px;
}
.eless-weiter-btn img {
    margin-left: 15px;
}
.eless-form #warning {
    border: 2px solid #041133;
    background-color: #f3f3f3;
    border-radius: 25px;
    padding: 10px 15px;
    margin: 10px 0;
    display: none;
    font-size: 16px;
}
.hide {
    display: none;
}
.eless-form a{
    color: rgba(50, 50, 50, 0.71);
}
.eless-form a:hover{
    color: #041133;
    text-decoration: none;
}
.eless-orange-color {
    color: #fe821e;
}
.border-while-loading{
    border: 2px solid #041133!important;
}
@media screen and (max-width: 350px){
    #duration-react input {
        min-width: inherit;
    }
    .eless-cta-btn{
        min-width: inherit;
    }
}
@media screen and (max-width: 1160px){
    /*header .header-content {*/
    /*    width: initial!important;*/
    /*}*/
}
@media screen and (max-width: 1005px){
    .eless-breadcrumb .bc-item {
        display: block;
        max-width: initial!important;
        flex: initial;
        padding: 10px 15px;
    }
    .eless-breadcrumb {
        padding-bottom: 20px;
        text-align: center;
        max-width: 400px;
        margin: 0 auto 50px auto;
    }
    .eless-breadcrumb div {
        text-align: left;
    }
}
@media screen and (min-width: 501px) and (max-width: 991px){
    h4 {
        font-size: 28px !important;
        margin-bottom: 15px !important;
    }
}
@media screen and (max-width: 500px){
    h4 {
        font-size: 20px !important;
    }
    #myProgress, #myProgress2, #myProgress3 {
        margin-top: 10px;
    }
}
@media screen and (min-width: 576px){
    .modal-dialog {
        margin: 3.75rem auto!important;
    }
}
@media screen and (min-width: 1111px){
    .voucher-text-full {
        font-weight: bold;
        font-style: italic;
    }
}
@media screen and (max-width: 1110px){
    .eless-zurueck-btn, .eless-weiter-btn {
        width: 100%!important;
        max-width: 100%!important;
        margin-bottom: 10px;
    }
}
.datefield-wrapper {
    display: inline;
    position: relative;
}
.datefield-wrapper .eless-icon {
    position: absolute;
    right: 40px;
    top: -10px;
}
input[type="date"]::-webkit-clear-button {
    display: none;
}
input[type="date"]::-ms-clear {
    display: none;
}
input::-ms-clear {
    width: 0;
    height: 0;
}
input[type=text]::-ms-clear {
    display: none;
}
.dropdown-arrow {
    position: absolute;
    left: 205px;
    width: 15px;
    top: 2px;
}
.dropdown-arrow.twolines {
    top: 14px;
}
.arrow-left {
    margin-right: 30px;
}
.arrow-right {
    margin-left: 30px;
}
.arrow-right-benefits {
    margin-left: 50px;
}
.info-icon {
    width: 22px;
    margin-left: 5px;
    cursor: pointer;
}
.maloid-info-wrapper {
    padding-left: 0!important;
    padding-top: 18px;
}
#maloid-info.bubble {
    width: 300px;
    left: -260px;
    top: 64px;
}
#maloid-info.bubble:before {
    left: initial!important;
    right: 11px;
}
#maloid-info.bubble:after {
    left: initial!important;
    right: 12px;
}
.bubble
{
    display: none;
    position: absolute;
    max-width: 300px;
    padding: 10px;
    background: #FFFFFF;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    border: #041133 solid 2px;
    z-index: 10;
    font-size: 16px;
}
.bubble:after
{
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 10px 20px;
    border-color: #FFFFFF transparent;
    display: block;
    width: 0;
    z-index: 1;
    top: -20px;
    left: 12px;
}
.bubble:before
{
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 11px 21px;
    border-color: #041133 transparent;
    display: block;
    width: 0;
    z-index: 0;
    top: -23px;
    left: 11px;
}
.spannungsebene-box{
    position: relative;
}
.info-wrapper{
    position: relative;
}
.info-wrapper .bubble{
    top: 50px;
    left: -8px;
    min-width: 230px;
}
.spannungsebene-box .bubble{
    top: 45px;
    left: -8px;
}
.hide-icon {
    display: none;
}
.blackholefix {
    padding-bottom: 18px;
}
.no-filters-result {
    padding: 50px 20% 0 20%;
    text-align: center;
}
/* loading animation */
.loading-animation-dotted {
    margin: 40px 0 80px 0;
}
.loading-animation-dotted b {
    margin-left: 30%;
}
.lds-ring {
    display: inline-block;
    position: absolute;
    width: 64px;
    left: 285px;
    top: 0;
    height: 20px;
}
#payment-loading-circle {
    left: 440px!important;
}
.lds-ring div {
    box-sizing: border-box;
    display: inline-block;
    position: absolute;
    width: 25px;
    height: 25px;
    margin: 6px;
    border: 3px solid #041133;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #041133 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.modalHitbox {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
}
/* Filter - Angebote */
.benefit-ul li {
    list-style-image:url('media/Aufzählungspunkt.png');
}
/* Tarife */
.tablet-col-1 {
    width: 150px;
    margin: 0 auto;
}
.tablet-tariff-btn {
    width: 100%;
    height: 100%;
    background-color: #000000;
    border: none;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}
#show-details span{
    cursor: pointer;
}
#show-details {
    padding-left: 7px!important;
}
.tarifblock .show-details-arrow {
    width: 15px;
    margin-left: 5px;
}
.netFeeBody {
    display: none;
}
.no-tariff{
    text-align: center;
    height: 277px;
    display: table-cell;
    vertical-align: middle;
    padding: 0 40px;
}
.tariff-name {
    margin-bottom: 30px;
}
.tariffDetailContainer {
    background-color: #f3f3f3;
    padding-top: 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: none;
}
.tariffDetailContainer .tab-content, .tariffDetailContainer .nav-tabs {
    width: 100%;
}
.tariffDetailContainer .tab-content {
    padding: 15px;
    border-bottom-left-radius: 25px;
    font-size: 18px;
}
.tariffDetailContainer .nav-tabs a {
    width:25%;
    border-bottom: 2px solid black;
    transition-duration: 0.2s;
    color: #041133;
    text-align: center;
    font-size: 18px;
}
.tariffDetailContainer .nav-tabs .nav-link.active {
    font-weight: bold;
    border: 2px solid black;
    border-bottom: none;
}
.tariffDetailContainer .nav-tabs .nav-link:hover {
    color: #fff;
    background-color:  #041133;
    transition-duration: 0.2s;
    border-bottom: 2px solid #041133;
}
.tariffDetailContainer .nav-tabs .nav-link.active:hover {
    font-weight: bold;
    border: 2px solid black;
    border-bottom: none;
    background-color: #fff;
    color: #041133;
}
.removeBottomRadius {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    transition-duration: 0.2s;
}
/* Consumption Points - Final Page */
.hiddenToggleDiv {
    display: none;
}
.validable {
    position: relative;
}
.o-checked-circle-1 {
    color: #41db72;
}
.o-delete-circle-1 {
    color: #FD7A64;
}
.validationIcon{
    position: absolute;
    top: 15px;
    right: 25px;
    font-size: 28px;
}
/* ContractAddFinisched TABs */
#contractSummary .tab-content {
    margin-bottom: 50px;
    background-color: #f3f3f3;
    padding: 15px;
}
#contractSummary .main-tabs .nav-item {
    width: 33.3%;
    text-align: center;
}
#contractSummary .tab-content {
    padding: 15px;
}
#contractSummary .nav-tabs a {
    border-bottom: 2px solid black;
    transition-duration: 0.2s;
    color: #041133;
    text-align: center;
}
#contractSummary .nav-tabs .nav-link.active {
    font-weight: normal;
    border: 2px solid black;
    border-bottom: none;
}
#contractSummary .nav-tabs .nav-link:hover {
    color: #fff;
    background-color:  #041133;
    transition-duration: 0.2s;
    border-bottom: 2px solid black;
}
#contractSummary .nav-tabs .nav-link.active:hover {
    font-weight: normal;
    border: 2px solid black;
    border-bottom: none;
    background-color: #fff;
    color: #041133;
}
.csPointsInfo .row {
    border-bottom: 1px solid lightgrey;
    margin: 0 10px 15px 10px;
 }
.csPointsInfo .csPointInfo:last-child .row {
    border-bottom: none!important;
    margin-bottom: 0!important;
}
.disabled-download-link {
    opacity: 0.5;
    cursor: default;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}
/* Email Message */
.email-sent-wrapper .o-mail-1 {
    font-size: 64px;
    color: #fe821e;
}
.email-sent-wrapper {
    text-align: center;
}
.email-sent-wrapper .eless-btn {
    float: inherit!important;
}
/* Choose Password */
.password-modal {
    font-family: NunitoRegular, serif;
}
.custom-border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding-bottom: 10px;
    margin-bottom: 15px;
}
.gender .control_indicator {
    position: absolute;
    top: 7px;
    left: 0;
    height: 24px;
    width: 24px;
    background: #f3f3f3;
    border: none;
}
.gender .control-radio .control_indicator {
    border-radius: 50%;
}
.gender .control-checkbox .control_indicator {
    border-radius: 25%;
}
.gender .control:hover input ~ .control_indicator,
.gender .control input:focus ~ .control_indicator {
    background: #f3f3f3;
}
.gender .control input:checked ~ .control_indicator {
    background: #041133;
}
/* if not disabled and checked */
.gender .control:hover input:not([disabled]):checked ~ .control_indicator,
.gender .control input:checked:focus ~ .control_indicator {
    background: #041133;
}
/* if disabled and checked */
.gender .control input:disabled:checked ~ .control_indicator{
    background: #041133;
}
.gender .control input:disabled:checked ~ .control_indicator:after{
    background: #041133;
}
.password-modal .modal-title{
    margin-bottom: 20px;
}
.password-modal .modal-text{
    margin-bottom: 20px;
    display: block;
    font-size: 16px;
    padding-bottom: 30px;
}
.password-modal .control-group {
    margin-bottom: 20px;
}
.position-spinner {
    right: 24px;
    position: absolute;
}
.password-modal .modal-content {
    padding: 10px 20px;
    border-top: 3px solid #fe821e!important;
}
.password-modal .modal-content .eless-weiter-btn {
    width: 100%;
}
.password-modal .modal-content .password-wrapper span:hover{
    cursor: pointer;
    color: #fe821e;
    transition-duration: 0.2s;
}
.password-modal .modal-content .password-wrapper span{
    position: absolute;
    top: 6px;
    right: 25px;
    font-size: 36px;
    transition-duration: 0.2s;
}
.password-modal .modal-content .password-wrapper{
    position: relative;
}
.password-modal .modal-content .password-field {
    margin-bottom: 50px!important;
}
.password-modal .modal-content .modal-header {
    border: none!important;
}
.password-modal .modal-content .modal-header .modal-title {
    margin: 0 auto;
    font-family: NunitoSemiBold, serif;
}
.react-datepicker__tab-loop {
    display: inline-block;
}
@media screen and (max-width: 768px) and (min-width: 768px){
    .order-img{
        margin-left: 30px!important;
    }
}
@media screen and (max-width: 991px) and (min-width: 769px){
    .hidden-tablet {
        display: none!important;
    }
    .voucher-text-container {
        text-align: left!important;
    }
    .order-img{
        margin-left: 30px!important;
    }
}
@media screen and (max-width: 991px) {
    .refresh-block-text, .refresh-block-button {
        text-align: center;
    }
    .personal-data-wrapper {
        border-right: 1px solid transparent;
    }
}
@media screen and (min-width: 940px){
    .vertical-center {
        width: 400px;
    }
}
@media screen and (min-width: 769px){
    .modal-form .ReactModal__Content {
        top: 10%!important;
        left: 30%!important;
        right: 30%!important;
        bottom: 10%!important;
        min-width: 240px;
        ms-overflow-style: none!important;
        overflow: -moz-scrollbars-none!important;
    }
}
@media screen and (max-width: 767px){
    #consumptionPointWrapper {
        margin-bottom: 0!important;
    }
    #consumptionPointWrapper .csPointBoxWrapper {
        padding-right: 0!important;
    }
    .bottom-row .eless-cta-btn{
        float: inherit;
    }
    .bottom-row div{
        text-align: center!important;
    }
    .bottom-row .cspoint-message {
        margin-bottom: 25px;
        margin-left: -15px;
    }
    #warning-content {
        text-align: center;
        margin-bottom: 10px;
    }
    .hidden-mobile {
        display: none!important;
    }
    .react-datepicker-wrapper {
        display: block!important;
    }
    .react-datepicker-wrapper .react-datepicker__input-container {
        width: 100%!important;
    }
    .react-datepicker-wrapper input {
        width: 100%!important;
        margin-right: 0!important;
    }
    .benefit-ul li {
        margin-bottom: 0!important;
    }
    header {
        margin-bottom: 0!important;
    }
    .voucher-text-container {
        text-align: center!important;
    }
    /* ConsumptionPoints Add Contract */
    .text {
        text-align: justify;
        display: inline-block;
    }
    #contractSummary .main-tabs .nav-item {
        width: 100%!important;
    }
    #contractSummary .nav-tabs a {
        border-top: 2px solid black;
        border-bottom: none!important;
        transition-duration: 0.2s;
        color: #041133;
        text-align: center;
        font-size: 24px;
        vertical-align: middle!important;
    }
    #contractSummary .nav-tabs .nav-link.active {
        color: white!important;
        background-color: #041133!important;
        border: none!important;
        border-bottom: none;
    }

}
/* Mobile style */
@media screen and (max-width: 575px){
    #consumptionPointWrapper .csPointBoxWrapper{
        padding-right: 0!important;
    }
    h1, h2 {
        font-size: 22px!important;
        margin-bottom: 25px!important;
    }
    input, select {
        font-size: 18px!important;
    }
    #root {
        font-size: 18px;
    }
    .tariff-price-info button {
        float: right!important;
    }
    .tariff-basic-info {
        margin-bottom: 20px!important;
    }
    .tarifblock {
        padding-right: 15px!important;
    }
}
@media screen and (min-width: 992px){
    #consumptionPointWrapper .csPointBoxWrapper:nth-child(3n+3) {
        padding-right: 0!important;
    }
    #myProgress {
        margin-top: 5px;
    }
    .hidden-lg {
        display: none!important;
    }
    .benefit-ul {
        padding-left: 22px;
    }
    .benefit-ul li {
        margin-bottom: 14px;
        padding-left: 10px;
    }
    .csPointsPadding {
        padding-left: 28px;
        padding-right: 28px;
    }
}
@media screen and (max-width: 991px){
    #consumptionPointWrapper .csPointBoxWrapper:nth-child(2n+2) {
        padding-right: 0!important;
    }
    .hidden-sm {
        display: none!important;
    }
    .benefit-ul {
        padding-left: 22px;
    }
    .benefit-ul li {
        margin-bottom: 10px;
        font-weight: bold;
        padding-left: 10px;
    }
    .eless-weiter-btn.benefits {
        margin-top: 0!important;
    }
}
@media screen and (max-width: 1137px){
    .eless-breadcrumb {
        line-height: 1.6;
    }
}
